import React from 'react'

import { Dialog } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import * as S from './styles'

const ImageDialog = ({ image, open, handleClose }: any) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <S.NoStyledButton onClick={handleClose}>
        <CloseIcon />
      </S.NoStyledButton>
      <img src={image} />
    </Dialog>
  )
}

export default ImageDialog
