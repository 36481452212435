import styled from 'styled-components'
import { TableBody as TableBodyPerson } from '@material-ui/core'

export const TableBody = styled(TableBodyPerson)`
  p {
    font-size: 12px;
    font-weight: 600;
  }
`

export const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  overflow: hidden;
  color: #373a3a;
`
export const ContainerContent = styled.div`
  margin-top: 30px;
`
export const ContainerUnitPhotos = styled.div`
  width: 100%;
  margin: 20px auto;
  max-width: ${process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? '960px' : 'unset'};
  margin-right: ${process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === '20px' ? '960px' : 'unset'};
  margin-left: ${process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === '20px' ? '960px' : 'unset'};

  @media (min-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const ContainerMap = styled.div`
  height: 280px;
  width: 100%;

  iframe {
    border-radius: 8px;
  }
`
export const CarouselItem = styled.div`
  margin: 15px;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 177px;
    height: 204px;
    object-fit: cover;
  }
`
