import * as React from 'react'

import { CarouselItem, NoStyledButton, Wrapper } from './org.cards-section-unit-images.style'

import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ImageDialog from '../ImageDialog'

export const CardsSectionUnitsImages = ({ banners }: any) => {
  const theme = useTheme()

  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  const [dialogImageOpen, setDialogImageOpen] = React.useState(false)
  const [dialogImage, setDialogImage] = React.useState('')
  return (
    <Wrapper>
      <ImageDialog
        open={dialogImageOpen}
        handleClose={() => setDialogImageOpen(false)}
        image={dialogImage}
      />
      <Container className="container-carousel-units-images" maxWidth="sm">
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          //showIndicators={downOfSm ? true : false}
          //swipeable={downOfSm ? true : false}
          //autoPlay={banners.length > 3 ? true : false}
          //infiniteLoop={downOfSm ? true : false}
          //centerSlidePercentage={downOfSm ? 100 : 33.33}
          showIndicators={banners.length > 1 ? true : false}
          swipeable={true}
          centerMode
          autoPlay={banners.length > 1 ? true : false}
          interval={5000}
          infiniteLoop={true}
          centerSlidePercentage={100}
        >
          {banners.map(
            (item, i) => (
              // item.link ? (
              //   <CarouselItem>
              //     <a href={item.link} key={i}>
              //       <img src={item.image} />
              //     </a>
              //   </CarouselItem>
              // ) : (
              <CarouselItem>
                <NoStyledButton
                  onClick={() => {
                    setDialogImage(item.image)
                    setDialogImageOpen(true)
                  }}
                >
                  <img src={item.image} />
                </NoStyledButton>
              </CarouselItem>
            )
            // )
          )}
        </Carousel>
      </Container>
    </Wrapper>
  )
}
