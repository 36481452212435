import styled from 'styled-components'

export const ExamsItemStyled = styled.span`
  font-size: 16px;
  color: #302a38;
  font-family: 'Signika';
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
`
