import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { PageProps } from '@app/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { diactriclessKebabCase } from 'utils/string'
// import { Separator } from 'atomic/legacy/obj.box'
import { getClientAndDoctorUrl, isDoctorUrl } from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { Query, Unidades, UnidadesHorariosAtendimento } from '@root/src/data/graphql/graphql-types'
import { Body, FontSize } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
// import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import { graphql } from 'gatsby'
import React from 'react'
import FacilityItem from './component/facility-item.component'

import { CardsSectionUnitsImages } from '@root/src/components/org.cards-section-unit-images/org.cards-section-unit-images'
import Title from 'site/src/components/landing-design-system/title'

import {
  ContainerMap,
  Wrapper,
  ContainerUnitPhotos,
  ContainerContent,
  TableBody
} from './unit-styles'

import {
  //Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Grid,
  useMediaQuery, 
  useTheme
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { ExamsItemStyled } from './component/exams-item.style'

const TableCustom = styled(TableContainer)({
  maxWidth: '450px',
  borderTop: '2px solid #9e9e9e70',
  borderLeft: '2px solid #9e9e9e70',
  borderRadius: '5px',
  boxShadow: 'unset',

  '& .MuiTableCell-head': {
    //minWidth: minWidthCell,
    backgroundColor: '#EFEFEF',
    color: '#302A38',
    padding: '8px 10px',
    lineHeight: '1.2',
    textAlign: 'center'
  },

  '& .MuiTableCell-root': {
    fontSize: '12px',
    color: '#302A38',
    fontWeight: '600',
    padding: '10px',
    borderBottom: '2px solid #9e9e9e70',
    borderRight: '2px solid #9e9e9e70'
  },

  '& .typographycomponentstyle__Body-blGPja': {
    fontSize: '12px',
    color: '#302A38',
    fontWeight: '600'
  }
})

export interface UnitDetailNewTemplateProps {
  unidade: Unidades
}

const UnitDetailNewTemplate: React.FunctionComponent<PageProps<
  UnitDetailNewTemplateProps,
  Query
>> = props => {
  const unidade = props.pageContext.unidade
  
  const theme = useTheme()
  const downOfMd = useMediaQuery(theme.breakpoints.down('sm'))
  const adjustMarginTop = downOfMd ? 80 : 0

  // BUSINESS-RULE: exams are separated by ";"
  const exams = unidade.exames.split(';')

  let banners = undefined as { image: string }[]
  if (unidade.fotos) {
    banners = unidade.fotos.slice(0, 3).map(foto => {
      return {
        image: foto.imagem.imgix_url
      }
    })
  }

  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${diactriclessKebabCase(unidade.slug)}`] = unidade.nome
  const staticInfo = props.data.cosmicjsInformacoesEstaticas.metadata
  const seoData = {
    canonicalUrl: getCanonicalUrl(
      props.data.site.siteMetadata.siteUrl,
      appPaths.units.path,
      diactriclessKebabCase(unidade.nome)
    ),
    title: `Unidade ${unidade.nome} › Laboratórios | Fleury Medicina e Saúde`,
    image: unidade.fotos[0].imagem.url,
    imageAlt: `Foto da unidade ${unidade.nome}`
  }

  const horarios = Object.keys(unidade.horariosAtendimento)
    .map(key => ({
      name: getWorkingHourNameFromKey(key as keyof UnidadesHorariosAtendimento),
      horarios: unidade.horariosAtendimento[key]
    }))
    .filter(item => item.horarios && item.horarios[0]?.horario_inicial)

  const facilities =
    unidade.facilidades && unidade.facilidades.filter(facility => facility.icone.imgix_url)
  const isDoctor = isDoctorUrl(props.location.pathname)

  const location = `${unidade.endereco}, ${unidade.bairro}, ${unidade.cidade} - ${unidade.estado}, CEP: ${unidade.cep}`

  const titleColumn1 = props.pageContext.unidade.horariosAtendimento?.segunda_a_sexta[0]?.titulo
  const titleColumn2 = props.pageContext.unidade.horariosAtendimento?.segunda_a_sexta[1]?.titulo
  const titleColumn3 = props.pageContext.unidade.horariosAtendimento?.segunda_a_sexta[2]?.titulo

  const observacoes = []
  const observacoesArray = Object.keys(unidade.horariosAtendimento)
    .map(key => (unidade.horariosAtendimento[key]
      .map(horario => {
        if (horario.observacao !== '') observacoes.push(horario.observacao)
      })))

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <IndexLayout location={props.location}>
        <Wrapper>
          <SEO socialMedia={seoData} />

          <Container style={{ marginTop: adjustMarginTop}} maxWidth="md">
            <TitleWithBreadcrumbRow addtionalDictionary={breadcrumbAdditionalDictionary} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Title color={'#373A3A'}>{unidade.nome}</Title>
                <a target="_blank" href={`https://maps.google.com/?q=${location}`}>
                  <Body className="address-units-detail">{location}</Body>
                </a>
                <ContainerUnitPhotos>
                  {banners && <CardsSectionUnitsImages banners={banners} />}
                </ContainerUnitPhotos>
              </Grid>
              
              {horarios.length > 0 && (
                <Grid item xs={12} sm={6}>
                  <Title color={'#373A3A'}>Horário da Unidade</Title>
                  <ContainerContent>
                    {/* <TableCustom component={Paper}> */}
                    <TableCustom>
                      <Table>
                        <TableHead>
                          <TableRow>                            
                            <TableCell colSpan={2}>{titleColumn1}</TableCell>
                            <TableCell colSpan={1}>{titleColumn2}</TableCell>
                            {titleColumn3 &&
                              <TableCell colSpan={1}>{titleColumn3}</TableCell>
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {horarios.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.name}</TableCell>
                              <TableCell style={{ minWidth: '7vw' }}>
                                <NewWorkingHourList
                                  workingHoursList={item.horarios.find(
                                    f => f.titulo === titleColumn1
                                  )}
                                />
                              </TableCell>
                              <TableCell style={{ minWidth: '7vw' }} >
                                <NewWorkingHourList
                                  workingHoursList={item.horarios.find(f => f.titulo === titleColumn2)}
                                />
                              </TableCell>
                              {titleColumn3 &&
                                <TableCell style={{ minWidth: '7vw' }}>
                                  <NewWorkingHourList
                                    workingHoursList={item.horarios.find(f => f.titulo === titleColumn3)}
                                  />
                                </TableCell>
                              }
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCustom>

                    {observacoes.length > 0 &&
                      <>
                        <Body style={{ marginTop: 12, marginBottom: -10, fontWeight: 'bold' }}>Observações:</Body>
                        <ul>
                          {observacoes.map(observacao => (
                            <li style={{ marginLeft: -10, fontWeight: 500 }}>{observacao}</li>
                          ))}
                        </ul>
                      </>
                    }
                  </ContainerContent>
                </Grid>
              )}              

              {facilities && facilities.length > 0 && (
                <Grid item xs={12} sm={12}>
                  <>
                    <Title color={'#373A3A'}>{staticInfo.facilidadesTitulo}</Title>

                    <ContainerContent>
                      {facilities.map(facility => (
                        <React.Fragment key={facility.facilidade}>
                          <FacilityItem                            
                            icon={
                              <LazyLoadImage                                
                                width={parseInt(FontSize.Large, 10)}
                                src={facility.icone.imgix_url}
                                alt={`Icone de ${facility.facilidade}`}
                              />                   
                            }
                            text={facility.facilidade}
                          />
                        </React.Fragment>
                      ))}
                    </ContainerContent>
                  </>
                </Grid>
              )}

              {exams && exams.length > 0 && exams[0] !== '' && (
                <Grid item xs={12} sm={6}>
                  <Title color={'#373A3A'}>{staticInfo.examesTitulo}</Title>
                  <ContainerContent>
                    <ul>
                      {exams.map(exam => (
                        <li>
                          <ExamsItemStyled>{exam}</ExamsItemStyled>
                        </li>
                      ))}
                    </ul>
                  </ContainerContent>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Title color={'#373A3A'}>Localização</Title>
                <ContainerContent>
                  <ContainerMap>
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      title="map"
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_MAPS}&q=${location}`}
                      allowFullScreen
                    />
                  </ContainerMap>
                </ContainerContent>
              </Grid>              
            </Grid>
          </Container>
          <LargeSeparatorRow />
        </Wrapper>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default UnitDetailNewTemplate

interface Horario {
  titulo?: string
  observacao?: string
  horario_inicial?: string
  horario_final?: string
}

interface IWorkingHourListProps {
  workingHoursList: Horario[]
}

const NewWorkingHourList: React.FunctionComponent<IWorkingHourListProps> = props => {
  return (
    <Body>
      {props.workingHoursList?.horario_inicial ?
        `${props.workingHoursList?.horario_inicial} às ${props.workingHoursList?.horario_final}`
        : '----'}
    </Body>
  )
}

const getWorkingHourNameFromKey = (key: keyof UnidadesHorariosAtendimento) => {
  switch (key) {
    case 'segunda_a_sexta':
      return 'Seg. a sex.'
    case 'sabado':
      return 'Sábado'
    case 'domingo':
      return 'Domingo'
    case 'feriado':
      return 'Feriado'
    default:
      return ''
  }
}

export const query = graphql`
  query UnitDetailNewTemplate {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allUnidades {
      edges {
        node {
          idLegado
          slug
        }
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "unidade-detalhe" }) {
      metadata {
        diferenciaisTitulo
        facilidadesTitulo
        contatoTitulo
        contatoConteudoHTML
        examesTitulo
      }
    }
  }
`
