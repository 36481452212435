import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow: hidden;
  margin: 0 auto;

  .container-carousel-units-images {
    padding: 0 !important;
  }

  .carousel .slide {
    background-color: white !important;
  }

  .control-dots {
    margin-top: 15px !important;
    position: initial !important;
    li {
      width: 10px !important;
      height: 10px !important;
      box-shadow: none !important;
      border: 1px solid ${props => props.theme.color.primary};
      opacity: 1 !important;

      &.selected {
        background-color: ${props => props.theme.color.primary};
      }
    }
  }

  @media (min-width: 768px) {
    .container-carousel-units-images {
      max-width: unset;
    }
  }

  @media (min-width: 1360px) {
    .carousel .slider {
      margin: 0 auto;
    }
  }
`

export const CarouselItem = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    img {
      width: 100%;
      height: 230px;
      object-fit: cover;

      @media (min-width: 768px) {
        border-radius: 8px;
        height: 170px;
        max-width: 300px;
      }

      @media (min-width: 1024px) {
        height: 220px;
      }
    }
  }

  @media (min-width: 768px) {
    margin: 0 10px;
  }
`

export const NoStyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
`
