import styled from 'styled-components'

export const NoStyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 8px;
  right: 8px;
  /* color: white; */

  &:hover {
    opacity: 0.5;
  }
`
